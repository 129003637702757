export class AppConstant {
  static readonly ACCESS_TOKEN = 'access_token'
  static readonly CURRENTROLE_TOKEN = 'userCurrentRole'
  static readonly CHIROPRACTOR = 'CHIROPRACTOR'
  static readonly LOCAL_BED = 'local_bed'
  static readonly ACCESS_TOKEN_SCAN_AUTH = 'access_token_scan_auth'
  static readonly CLINIC_ID = 'clinic_id'
}
export const API_URL = process.env.REACT_APP_API_BASE_URL || '/api'

export enum QueueStatusEnum {
  WAITING = 'waiting',
  COMPLETED = 'completed',
  CALLED_IN = 'called_in',
  SERVICE_IN_PROGRESS = 'service_in_progress',
}

export enum TreatmentTrackerType {
  single = 'single',
  symptomatic_m = 'symptomatic_m',
  symptomatic_h = 'symptomatic_h',
  preventative = 'preventative',
}
