import React, { Ref, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Avatar, Button, Drawer, Image, Layout, Modal, Segmented, Spin, theme } from 'antd'
import axios from 'axios'
import { format } from 'date-fns'
import styled from 'styled-components'
import { API_URL, AppConstant } from 'AppConstant'
import { useAuth } from 'hooks/useAuth'
import { useUpcomingAndPreviousPolling } from 'hooks/useUpcomingAndPreviousPolling'
import Dob from 'images/Cdob.svg'
import Mail from 'images/Cmail.svg'
import Phone from 'images/Cphone.svg'
import Cross from 'images/cross.svg'
import User from 'images/Cuser.svg'
import FullScreen from 'images/fullscreen.svg'
import reload from 'images/reload.svg'
import CurrentTab from 'tabs/current/CurrentTab'
import LobbyTab from 'tabs/lobby/LobbyTab'
import NotesTab from 'tabs/notes/NotesTab'
import { useRecordingHook } from './hooks/useRecordingHook'
import CaptureImg from './images/captureImg.svg'
import KiroLogo from './images/kiro-logo-full.svg'
import Logout from './images/logout.svg'
import NotesIcon from './images/notes.svg'
import NowIcon from './images/now.svg'
import ProfileIcon from './images/profile-icon.svg'
import QueueIcon from './images/queue.svg'
import { callInNextPatientAsync, resetCalledInTwiceError } from './redux/lobby'
import { getProfile } from './redux/me'
import { getNotesByStatusAsync } from './redux/notes'
import { useAppDispatch, useAppSelector } from './redux/store'
import { APIStatus } from './redux/types'

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  gap: 12px;
  width: 100%;
`
const InitialLetter = styled.div`
  width: 50px;
  border-radius: 50%;
  text-transform: uppercase;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #10123d;
  border: 1px solid #343755;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #d2d3e0;
  margin-bottom: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

const InputWrapper = styled.div``

const InputContent = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 6px;
  border: 1px solid #2a2d50;
  color: #fffeff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

const Email = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 6px;
  border: 1px solid #2a2d50;
  color: #fffeff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

const ProfileImg = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #e5e7eb;
`
const Name = styled.span`
  color: #e5e7eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  lineh-eight: normal;
  text-transform: capitalize;
`

export const PopUp = styled(Modal)`
  width: 411px !important;
  background-color: #10123d;
  border-radius: 4px;
`

export const PopUpMessage = styled.div`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: 90%;
  margin: 20px 28px;
  padding-right: 10px;
`
export const PopUpButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 20px;
`

//import { socket } from './socket'
const { Header, Content, Footer } = Layout
const AuthenticatedApp = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  const [activeTab, setActiveTab] = useState('Current')
  const { currentRole, signOut, user } = useAuth()
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const profileStatus = useAppSelector((state) => state.me.profileStatus)
  const profile = useAppSelector((state) => state.me.profile)
  const [image, setImage] = useState<any>(null)
  const [load, setLoad] = useState(false)
  const queue = useAppSelector((state) => state.lobby.queue)
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [errorPopUp, setErrorPopUp] = useState(false)
  const callInError = useAppSelector((state) => state.lobby.callInError)
  const currentPatient = useAppSelector((state) => state.lobby.currentPatient)
  const priorityPatient = useAppSelector((state) => state.lobby.priorityPatient)
  const notes = useAppSelector((state) => state.notes.notes)
  const tabRef = useRef(null)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { startRecording, stopRecording } = useRecordingHook()

  const { startPolling, stopPolling } = useUpcomingAndPreviousPolling()

  const [recordingUploadUrl, setRecordingUploadUrl] = useState<any>(null)

  const getChiroProfile = useCallback(() => {
    setOpen(true)
    if (profileStatus === APIStatus.idle) {
      dispatch(getProfile())
    }
  }, [profileStatus, dispatch, setOpen])

  // useEffect(() => {
  //   console.log('recordedAudio')
  //   console.log(recordedAudio)
  //   if (recordedAudio && recordingUploadUrl) {
  //     fetch(recordingUploadUrl, {
  //       method: 'PUT',
  //       body: recordedAudio,
  //       // mode: 'no-cors',
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error('Failed to upload audio: ' + response.statusText)
  //         }
  //         return response.text()
  //       })
  //       .then((data) => {
  //         console.log('Audio uploaded successfully:', data)
  //       })
  //       .catch((error) => {
  //         console.error('Error uploading audio:', error)
  //       })
  //     resetRecording()
  //     setRecordingUploadUrl(null)
  //   }
  // }, [recordedAudio])

  // utils/formatPhoneNumber.js

  const formatPhoneNumber = useCallback(
    (phoneNumber: string) => {
      const cleaned = ('' + phoneNumber).replace(/\D/g, '')

      if (cleaned.length !== 11) {
        return phoneNumber
      }

      const restOfNumber = cleaned.slice(-10)

      return `${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3, 6)}-${restOfNumber.slice(6, 10)}`
    },
    [profile],
  )

  const handleCapturePhoto = async (e: any) => {
    setLoad(true)
    if (e.target.files) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      try {
        const response = await axios.post(`${API_URL}/auth/me/profile-picture`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AppConstant.ACCESS_TOKEN)}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        console.log(response, 'what res')

        if (response?.data?.profilePic) {
          setImage(response?.data?.profilePic)
          setLoad(false)
        } else {
          setLoad(false)
          console.error('Unexpectedformat:', response)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      setLoad(false)
    }
  }

  const enterFullscreen = () => {
    const elem: any = tabRef.current
    const doc: any = document
    if (elem) {
      if (isFullScreen) {
        if (doc.exitFullscreen) {
          doc.exitFullscreen()
          setIsFullScreen(false)
        } else if (doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen()
          setIsFullScreen(false)
        } else if (doc.msExitFullscreen) {
          doc.msExitFullscreen()
          setIsFullScreen(false)
        }
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
          setIsFullScreen(true)
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
          setIsFullScreen(true)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
          setIsFullScreen(true)
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getNotesByStatusAsync())
  }, [])

  // useEffect(() => {
  //   function onConnect() {
  //     console.log('connected')
  //   }

  //   function onDisconnect() {
  //     console.log('dis connected')
  //   }

  //   // function onFooEvent(value) {
  //   //   setFooEvents((previous) => [...previous, value])
  //   // }

  //   setInterval(() => {
  //     socket.emit('events', 'workld', (val: any) => {
  //       console.log(val)
  //     })
  //   }, 3000)

  //   socket.on('connect', onConnect)
  //   socket.on('disconnect', onDisconnect)
  //   //socket.on('foo', onFooEvent)

  //   return () => {
  //     socket.off('connect', onConnect)
  //     socket.off('disconnect', onDisconnect)
  //   }
  // }, [])
  useEffect(() => {
    const scrollableContainer = document.getElementById('ref')
    scrollableContainer?.addEventListener(
      'touchstart',
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      { passive: false },
    )
  })

  useEffect(() => {
    if (callInError && callInError === 'calledInTwice') {
      setErrorPopUp(true)
    }
  }, [callInError])

  useEffect(() => {
    if (activeTab === 'Lobby') {
      startPolling()
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [activeTab])

  return (
    <div
      id="ref"
      style={{ height: '100vh', width: '100vw', overflow: 'hidden', touchAction: 'none', WebkitUserSelect: 'none' }}
      ref={tabRef}
    >
      <Layout style={{ width: '100%', height: '100%' }}>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #13152D',
            position: 'fixed',
            width: '100%',
            paddingLeft: 30,
            paddingRight: 12,
            // top: 0,
          }}
        >
          <Image
            preview={false}
            onClick={() => {
              setActiveTab('Lobby')
            }}
            src={KiroLogo}
          ></Image>
          <Segmented
            size="large"
            value={activeTab}
            block
            style={{ fontWeight: 'bold', height: 40, width: 375 }}
            options={[
              { label: 'Current', value: 'Current' },
              {
                label: `Lobby ${queue?.length > 0 ? `(${queue?.length})` : ''}`,
                value: 'Lobby',
              },
              {
                label: `Notes ${notes?.length > 0 ? `(${notes?.length})` : ''}`,
                value: 'Notes',
              },
            ]}
            onChange={(value) => {
              // string
              setActiveTab(value)
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
            <img
              src={reload}
              alt="reload"
              onClick={() => {
                if (currentPatient) {
                  setPopUpOpen(true)
                } else {
                  window.location.reload()
                }
              }}
            />
            <div style={{ marginRight: 12 }}>
              {user && user.profilePic ? (
                <Avatar size={50} src={user?.profilePic} onClick={getChiroProfile}></Avatar>
              ) : (
                <InitialLetter onClick={getChiroProfile}>{user?.firstname[0] + user?.lastname[0]}</InitialLetter>
              )}
            </div>
            {/* <div
              onClick={() => {
                enterFullscreen()
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={FullScreen}></img>
            </div> */}
          </div>
        </Header>
        <Content
          style={{
            backgroundColor: '#050624',
            padding: '20px 48px',
            position: 'fixed',
            width: '100%',
            top: 100,
            height: '100%',
            // touchAction: 'none',
            // WebkitUserSelect: 'none',
          }}
        >
          {/* <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              top: 0,
              marginTop: '100px',
              paddingRight: '80px',
            }}
          >
            <div
              onClick={() => {
                enterFullscreen()
              }}
              style={{
                color: '#007F7C',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={FullScreen}></img>
            </div>
          </div> */}
          {activeTab === 'Current' && (
            <CurrentTab
              startRecording={startRecording}
              stopRecording={stopRecording}
              // recordedAudio={recordedAudio}
              // resetRecording={resetRecording}
              setUploadUrl={(url: any) => {
                ;(window as any).ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    action: 'upload-url',
                    url,
                  }),
                )
                // setRecordingUploadUrl(url)
              }}
            ></CurrentTab>
          )}
          {activeTab === 'Lobby' && <LobbyTab></LobbyTab>}
          {activeTab === 'Notes' && <NotesTab></NotesTab>}
          <Drawer
            style={{ paddingLeft: '15px', paddingTop: '20px', touchAction: 'none', WebkitUserSelect: 'none' }}
            onClose={() => {
              setOpen(false)
            }}
            extra={
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '6px',
                  background: '#2A2D56',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  setOpen(false)
                }}
              >
                <img src={Cross} style={{ cursor: 'pointer' }} alt="CloseSvg"></img>
              </div>
            }
            closable={false}
            open={open}
            title={'Profile'}
          >
            {profileStatus === APIStatus.pending ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin></Spin>
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    touchAction: 'none',
                    WebkitUserSelect: 'none',
                  }}
                >
                  <div>
                    {profile?.profilPic && (
                      <ProfileWrapper>
                        <Avatar size={50} src={image || profile?.profilPic}></Avatar>
                      </ProfileWrapper>
                    )}
                    <InfoWrapper>
                      <InputWrapper>
                        <Label>
                          <img src={User} style={{ marginRight: '8px' }}></img>First Name
                        </Label>
                        <InputContent style={{ textTransform: 'capitalize' }}>{profile?.firstname}</InputContent>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>
                          <img src={User} style={{ marginRight: '8px' }}></img>Last Name
                        </Label>
                        <InputContent style={{ textTransform: 'capitalize' }}>{profile?.lastname}</InputContent>
                      </InputWrapper>
                      {/* <InputWrapper>
                        <Label>
                          <img src={Dob} style={{ marginRight: '8px' }}></img>Date of Birth
                        </Label>
                        <InputContent>
                          {profile && profile.dob && format(new Date(profile?.dob), 'MM-dd-yyyy')}
                        </InputContent>
                      </InputWrapper> */}
                      <InputWrapper>
                        <Label>
                          <img src={Phone} style={{ marginRight: '8px' }}></img>Phone
                        </Label>
                        <InputContent>{profile && profile?.phone && formatPhoneNumber(profile?.phone)}</InputContent>
                      </InputWrapper>
                      <InputWrapper>
                        <Label>
                          <img src={Mail} style={{ marginRight: '8px' }}></img>Email
                        </Label>
                        <InputContent>{profile?.email}</InputContent>
                      </InputWrapper>
                    </InfoWrapper>
                  </div>
                  <div style={{ marginBottom: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
                    <Button
                      children="Logout"
                      onClick={() => {
                        signOut().finally(() => {})
                      }}
                      style={{
                        width: '100%',
                        background: 'transparent',
                        border: '1px solid #007F7C',
                        borderRadius: '6px',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        color: '#007F7C',
                        backgroundImage: `url(${Logout})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '28% center',
                      }}
                    ></Button>
                  </div>
                </div>
              </>
            )}
          </Drawer>
          <PopUp open={popUpOpen} centered mask={true} maskClosable={true} footer={null} closable={false}>
            <PopUpMessage>
              Refreshing the page will result in the loss of the current patient session. Are you certain you wish to
              proceed?
            </PopUpMessage>
            <PopUpButtonContainer>
              <Button
                children={'No'}
                style={{
                  width: 89,
                  height: 38,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#007f7c',
                  border: '1px solid #007f7c',
                  background: 'transparent',
                }}
                onClick={() => {
                  setPopUpOpen(false)
                }}
              ></Button>
              <Button
                children={'Yes'}
                style={{
                  width: 89,
                  height: 38,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#007f7c',
                }}
                onClick={() => {
                  window.location.reload()
                }}
              ></Button>
            </PopUpButtonContainer>
          </PopUp>
          <PopUp open={errorPopUp} centered mask={true} maskClosable={true} footer={null} closable={false}>
            <PopUpMessage style={{ margin: '20px 42px' }}>
              This patient has been assigned to another chiropractor.
            </PopUpMessage>
            <PopUpButtonContainer>
              <Button
                children={queue?.length > 0 ? 'Call-In Next Patient' : 'Done'}
                style={{
                  width: 'fit-content',
                  height: 38,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#007f7c',
                  color: '#e5e7eb',
                }}
                onClick={() => {
                  setErrorPopUp(false)
                  dispatch(resetCalledInTwiceError())
                  if (queue && queue?.length > 0) {
                    dispatch(callInNextPatientAsync({ queue, priorityPatient }))
                  }
                }}
              ></Button>
            </PopUpButtonContainer>
          </PopUp>
        </Content>
      </Layout>
    </div>
  )
}

export default AuthenticatedApp
